<template>
    <div class="tabs-box" :style="{ 'justify-content': equalDivider ? 'space-around' : 'space-evenly','background': bgColor }">
        <div v-for="(tab) in tabsArr" :key="tab.id" :class="[tab.isSelected ? 'tab-selected' : 'tab']" @click="changeTab(tab.id)">
            {{ tab.text }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
    created(){
        this.init()
    },
    activated(){
        this.init()
    },
    mounted(){
    },
    components: {
    },
    data(){
        return {
            tabsArr:[]
        }
    },
    methods:{
        changeTab(id, isClick){
            let tempArr = []
            this.tabsArr.forEach(tab => {
                let temp = {}
                temp.id = tab.id
                temp.text = tab.text
                if(id === tab.id){
                    temp.isSelected = true
                } else {
                    temp.isSelected = false
                }
                tempArr.push(temp)
            })
            this.tabsArr = tempArr
            if(!isClick){ // 该方法由点击触发
                if(this.parent){
                    this.$emit('changeTaaab', id)
                } else {
                    this.$bus.$emit('changeTaaab', id)
                }
            }
            sessionStorage.setItem('tabId', String(id))
        },
        init(){
            this.tabsArr.length = 0
            for(let i = 0; i < this.tabs.length; i++){
                let temp = {}
                temp.id = i
                temp.text = this.tabs[i]
                if(sessionStorage.getItem('tabId')){
                    if(i === Number(sessionStorage.getItem('tabId'))){
                        temp.isSelected = true
                    } else {
                        temp.isSelected = false
                    }
                } else {
                    if(i === 0){
                        temp.isSelected = true
                    } else {
                        temp.isSelected = false
                    }
                }
                this.tabsArr.push(temp)
            }
        }
    },
    props:{
        tabs:{
            type: Array,
            default: ()=> ['全部','待付款','待发货','待收货','售后']
        },
        equalDivider:{
            type: Boolean,
            default: true
        },
        bgColor:{
            type: String,
            default: '#fdfdfd'
        },
        parent:{
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped lang="scss">
.tabs-box {
    background: #fdfdfd;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    top: 50px;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    height: 45px;
    max-width: 625px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 20px;
    font-size: 16px;
    color: rgba(0,0,0,0.5);
    .tab-selected {
        border-bottom: 2px #fc5f10 solid;
        color: #000;
        cursor: pointer;
    }
    .tab {
        border-bottom: 2px rgba(0,0,0,0) solid;
        cursor: pointer;
    }
}
</style>
