<template>
    <div class="coupon-box" id="coupon-box">
        <title-nav :title="'其他卡券'"/>
        <tabs @changeTaaab="changeTaaab" :tabs="['已使用','已过期']"/>
        <div class="coupon-list">
            <div class="card" v-for="(coupon, i) in couponList" :key="i">
              <coupon-card
                :amount="coupon.discount_amount"
                :expirationDate="coupon.expiration_date"
                :code="coupon.coupon_code"
                :used="coupon.status"
                :expired="tabId === '1'"
                :couponType="coupon.type"
                :couponRange="coupon.range"
                :title="coupon.title"/>
            </div>
        </div>
        <div v-if="loading" class="loading">加载中...</div>
        <div v-if="couponList.length === 0 && !loading">
            <div class="none-list">
                <img src="../../assets/images/none.svg" alt="">
                <div class="none">您还没有优惠券喔~</div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryCouponById } from '@/api/api'
import TitleNav from '../../components/nav/TitleNav.vue'
import CouponCard from '../../components/card/CouponCard.vue'
import Tabs from '../../components/tabs/index.vue'

export default {
  name: 'CouponMore',
  components: {
    Tabs,
    TitleNav,
    CouponCard
  },
  created(){
    this.$bus.$on('changeTaaab', this.changeTaaab)
  },
  mounted(){
    this.getData('2')
  },
  data:()=>{
    return {
      couponList:[],
      loading: false,
      tabId: '0',
    }
  },
  methods:{
    getData(type){
      let uidTemp = JSON.parse(localStorage.getItem('userInfo'))[0].uid
      this.loading = true
      queryCouponById({ limit: 100, uid: uidTemp, type: type }).then(res => {
        this.couponList.length = 0
        this.loading = false
        res.data.data.forEach(item => {
          let temp = {}
          temp.discount_amount = item.discount_amount
          temp.expiration_date = item.expiration_date
          temp.coupon_code = item.coupon_code
          temp.status = item.status ? true : false
          temp.type = item.type === 1 ? 'reduction' : 'discount'
          temp.range = item.range
          temp.title = item.title
          this.couponList.push(temp)
        })
      })
    },
    changeTaaab(id){
      this.couponList.length = 0
      this.tabId = String(id)
      if(String(id) === '0'){
        this.getData('2') // 2-已使用
      } else if(String(id) === '1'){
        this.getData('3') // 3-已过期
      }
    },
  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.coupon-box {
    height: 100%;
    padding-top: 50px;
    .coupon-list {
      margin: 60px 0;
      .card {
        margin: 15px;
      }
    }
}
.none-list {
    margin: 60px auto 20px;
    width: 100%;
    max-width: 625px;
    .none {
        margin: 10px 0 0;
        color: #969699;
    }
    img {
        min-height: 142px;
    }
}
.loading {
    color: #969699;
    margin: 0px auto 20px;
    font-size: 14px;
}
.bottom-more {
  background: #fff;
  color: #000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  line-height: 50px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>