<template>
    <div :class="['coupon-card', { 'selected': selected, 'used': used }]">
      <div class="invoice-bg"></div>
      <div class="ticket"  v-if="couponType==='discount'">
        <div class="amount">{{ amount }}</div>
        <div class="currency">折</div>
      </div>
      <div class="ticket" v-else>
        <div class="amount">{{ amount }}</div>
        <div class="currency">元</div>
      </div>
      <div class="details">
        <div class="title">{{ title }}</div>
        <div class="expiration-date">有效期：{{ expirationDate }}</div>
      </div>
      <div class="status">
        <div v-if="used" class="status-label">已使用</div>
        <div v-if="expired" class="status-label">已过期</div>
        <div v-if="isClaimed" class="status-label">已领取</div>
      </div>
      <button v-if="showButton && !used && claim && !isClaimed" class="claim-button" @click="claimCoupon">领取优惠券</button>
      <button v-if="showButton && !used && !claim && !expired && !isClaimed" class="claim-button" @click="goUsedPage">去使用</button>
    </div>
</template>

<script>
export default {
    methods:{
      goUsedPage(){
        let coupon = {}
        coupon.code = this.code
        coupon.range = this.couponRange
        this.$emit('goUsedPage', coupon)
      },
      claimCoupon(){
        let coupon = {}
        coupon.code = this.code
        coupon.range = this.couponRange
        this.$emit('claimCoupon', coupon)
      }
    },
    props: {
      couponType:{
        type: String,
        default: ''
      },
      couponRange:{
        type: Number,
        default: 1
      },
      code:{
        type: String,
        default:''
      },
      amount: {
        type: Number,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      expirationDate: {
        type: String,
        required: true
      },
      selected: {
        type: Boolean,
        default: false
      },
      used: {
        type: Boolean,
        default: false
      },
      expired: {
        type: Boolean,
        default: false
      },
      isClaimed: {
        type: Boolean,
        default: false
      },
      showButton: {
        type: Boolean,
        default: true
      },
      claim: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
  
<style scoped>
  .coupon-card {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
  }
  .invoice-bg {
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    background-color: #f5f5f5;
    z-index: -1;
  }
  .ticket {
    display: flex;
    align-items: center;
    padding-right: 10px;
    border-right: 1px solid #ccc;
    margin-right: 10px;
  }
  .amount {
    font-size: 24px;
    font-weight: bold;
  }
  .currency {
    font-size: 12px;
    margin-left: 2px;
  }
  .details {
    flex-grow: 1;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .expiration-date {
    font-size: 12px;
    color: #999;
  }
  .claim-button {
    margin-left: auto;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    background-color: #f60;
    border: none;
    border-radius: 4px;
  }
  .status {
    display: flex;
    align-items: center;
  }
  .status-label {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: #999;
    padding: 5px 10px;
    border-radius: 4px;
    margin-left: 10px;
  }
  .selected {
    background-color: #f5f5f5;
  }
  .used {
    /* filter: grayscale(100%); */
  }
</style>
  